import {Modal} from 'react-bootstrap-v5'
import {FC, useMemo} from 'react'
import {IDialogProps} from './Dialog.model'
import clsx from 'clsx'
import SVG from 'react-inlinesvg'
import { toAwsS3Url } from '_metronic/helpers'

export const Dialog: FC<IDialogProps> = ({
  show,
  dismissButton,
  children,
  title,
  onClose,
  size,
  borderLine = true,
  className = '',
  contentShadow = false,
}) => {
  const dialogSize = useMemo(() => {
    switch (size) {
      case 'extraLarge':
        return 'xl'
      case 'large':
        return 'lg'
      case 'small':
        return 'sm'
      default:
        return ''
    }
  }, [size])
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <Modal
      show={show}
      fullscreen={!dialogSize && size === 'fullScreen' ? true : undefined}
      {...(dialogSize ? {size: dialogSize} : {})}
      onHide={() => {}}
      contentClassName={clsx(className, !contentShadow && 'shadow-none')}
      dialogClassName='min-h-75 box-sizing d-flex align-items-center justify-content-center'
    >
      {(dismissButton || title) && (
        <div
          className={clsx(
            'border-0 border-gray-300 align-items-center',
            borderLine && 'border-bottom border-1',
            title && 'modal-header pt-4 pb-4',
            dismissButton && title && 'justify-content-between',
            !dismissButton && title && 'justify-content-center',
            dismissButton && !title && 'd-flex p-2 mx-3 justify-content-end'
          )}
        >
          {title && <h2 className={clsx('fs-5', !dismissButton && 'text-center')}>{title}</h2>}
          {dismissButton && (
            <div
              className='btn btn-lg btn-icon btn-active-color-primary'
              onClick={() => handleClose()}
            >
              <span className='svg-icon svg-icon-2'>
                <SVG src={toAwsS3Url('/media/icons/duotune/general/gen034.svg')} />
              </span>
            </div>
          )}
        </div>
      )}
      <div className='modal-body scroll-y px-8 pt-5 pb-5'>{children}</div>
    </Modal>
  )
}

export default Dialog
